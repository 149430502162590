<template>
  <div class="page">意向标签</div>
</template>

<script>
export default {
  name: "Intention",
};
</script>

<style>
</style>